<template>
  <img alt="Vue logo" src="./assets/logo.png">
  <zhuke msg="Welcome to Your Vue.js App"/>
</template>

<script>
import zhuke from './components/zhuke.vue'

export default {
  name: 'App',
  components: {
    zhuke
  }
}
</script>
